<template>
  <div
    class="container mx-auto flex justify-center items-center"
    style="min-height: 600px"
  >
    <el-card
      v-for="item in roles"
      :key="item"
      class="mx-4 p-6 cursor-pointer"
      shadow="hover"
      @click.native="selectRole(item)"
    >
      {{ roleText[item] }}
    </el-card>
  </div>
</template>

<script>
import { setRole } from "@/api/user";
export default {
  name: "Roles",
  data() {
    return {
      roles: [],
      roleText: {
        admin: "教管",
        teacher: "教师",
      },
      to: "/",
    };
  },
  mounted() {
    this.roles = this.$store.getters.roles;
    const eduRole = this.$store.getters.eduRole;
    this.to = this.$route.query.to;
    if (eduRole) {
      if (this.to === "/roles/index") {
        this.$router.push("/");
      } else {
        this.$router.push(this.to);
      }
    }
  },
  methods: {
    selectRole(val) {
      setRole({ role: val }).then(() => {
        this.$store.dispatch("user/setEduRole", { role: val });
        if (this.to === "/roles/index") {
          this.$router.push("/");
        } else {
          this.$router.push(this.to);
        }
      });
    },
  },
};
</script>

<style>
</style>